export const WebIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <rect
      x="1"
      y="3.5"
      width="16"
      height="12"
      rx="2"
      stroke="#525266"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="8.5" y="15" width="1" height="4" rx="0.5" stroke="#525266" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="5.5" y="19" width="7" height="1" rx="0.5" stroke="#525266" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
