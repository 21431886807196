import React from 'react';
/* eslint-disable */
export function Wifi(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.625 11.597C22.3946 9.73188 18.7302 8.75 15 8.75C11.2698 8.75 7.60541 9.73188 4.375 11.597"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.5 15.7596C20.2197 14.4431 17.6331 13.75 15 13.75C12.367 13.75 9.78029 14.4431 7.5 15.7596"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20 20.0897C18.4798 19.2121 16.7554 18.75 15 18.75C13.2446 18.75 11.5202 19.2121 10 20.0897"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
